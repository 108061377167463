import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  SimpleGrid,
} from '@chakra-ui/react';
import { FaShieldAlt, FaPercent, FaMicrochip, FaGlobe, FaMobile } from 'react-icons/fa';

// Componente para la tarjeta de beneficio
const BenefitCard = ({ icon: Icon, title, description }) => {
  return (
    <Box
      p={6}
      bg={useColorModeValue('white', 'gray.700')}
      borderRadius="lg"
      boxShadow="xl"
      transition="all 0.3s"
      _hover={{ 
        transform: 'scale(1.75)', 
        translateY: '-5px',
        boxShadow: '2xl'
      }}
    >
      <Icon size="2em" color={useColorModeValue('#3182CE', '#63B3ED')} />
      <Heading size="md" mt={4} mb={2}>
        {title}
      </Heading>
      <Text color={useColorModeValue('gray.600', 'gray.300')}>
        {description}
      </Text>
    </Box>
  );
};

const AboutUs = () => {
  return (
    <Box>
      <Box 
        bg={useColorModeValue('gray.50', 'gray.900')} 
        py={8}
        position="relative"
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: "url('https://img.freepik.com/free-photo/business-people-shaking-hands-together_53876-30568.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.50,
          zIndex: 0,
          filter: 'blur(0.5px)'
        }}
      >
        <Container maxW="container.xl" position="relative" zIndex={1}>
          <VStack spacing={12}>
            <Box textAlign="center" w="full">
              <Heading mb={4} size="2xl" color="blue.600">
                Quienes Somos
              </Heading>
              <Text 
                fontSize="xl" 
                color={useColorModeValue('gray.800', 'gray.100')} 
                maxW="2xl" 
                mx="auto"
                fontWeight="medium"
              >
                Somos una empresa fintech innovadora, comprometida con transformar la experiencia de pago de 
                nuestros usuarios en el mundo digital. Nuestro Datáfono Crypto aborda el desafío de aceptar pagos 
                en criptomonedas de manera rápida, segura y accesible, brindando una solución moderna que 
                responde a las necesidades actuales del comercio.
              </Text>
            </Box>

            <Box w="full" bg={useColorModeValue('gray.50', 'gray.900')} py={12} px={4} borderRadius="lg">
              <VStack spacing={8}>
                <Heading size="lg" mb={4}>
                  Nuestra Misión
                </Heading>
                <Text fontSize="lg" textAlign="center" maxW="3xl">
                  En un mundo donde el comercio es la base del crecimiento global, es imperativo ofrecer formas de 
                  pago actualizadas y seguras. Hoy en día, existe una creciente necesidad de que los clientes puedan 
                  realizar y recibir pagos en criptomonedas de manera eficiente y sin complicaciones. Por eso, 
                  presentamos nuestro datáfono virtual para crypto, una solución que permite a cualquier persona o 
                  negocio procesar pagos de forma segura y sencilla, con una comisión competitiva del 2%.
                </Text>
              </VStack>
            </Box>

            <Box py={12}>
              <Container maxW="container.xl">
                <VStack spacing={12}>
                  <Heading textAlign="center" size="xl" color="blue.600">
                    Nuestros Beneficios
                  </Heading>
                  <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8}>
                    <BenefitCard
                      icon={FaShieldAlt}
                      title="Transacciones Seguras y Simples"
                      description="Procesa pagos de manera rápida y protegida con nuestra plataforma de última generación."
                    />
                    <BenefitCard
                      icon={FaPercent}
                      title="Comisión Competitiva"
                      description="Solo un 2% de comisión, lo que garantiza más ganancias para tu negocio."
                    />
                    <BenefitCard
                      icon={FaMicrochip}
                      title="Tecnología Avanzada"
                      description="Solución innovadora y accesible para clientes que buscan modernizar sus métodos de pago por medio de códigos QR con la información para realizar el pago."
                    />
                    <BenefitCard
                      icon={FaGlobe}
                      title="Acceso Global"
                      description="Recibe pagos desde cualquier lugar, sin restricciones geográficas."
                    />
                    <BenefitCard
                      icon={FaMobile}
                      title="Pagos Flexibles"
                      description="Las personas y comercios pueden disponer de un datáfono virtual desde cualquier computadora, tablet o celular, sin necesidad de equipos adicionales."
                    />
                  </SimpleGrid>
                </VStack>
              </Container>
            </Box>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUs; 