import React, { useState } from 'react';
import {
  Box,
  // Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  HStack,
  Text,
  useToast,
  InputGroup,
  InputLeftElement,
  InputRightAddon,
  Divider,
  Badge,
} from '@chakra-ui/react';
import { 
  FaBitcoin, 
  FaWallet, 
  FaExchangeAlt,
  FaDollarSign 
} from 'react-icons/fa';
import { 
  SiCardano, 
  SiPolkadot, 
} from 'react-icons/si';
import StyledButton from '../common/StyledButton';

const PaymentForm = () => {
  const [formData, setFormData] = useState({
    amount: '',
    blockchain: 'ETH',
    cryptoType: 'ETH',
    recipientAddress: '',
    description: ''
  });
  
  const blockchains = {
    ETH: {
      name: 'Ethereum',
      tokens: ['ETH', 'USDT', 'USDC'],
      icon: FaExchangeAlt,
      addressFormat: '0x...'
    },
    BSC: {
      name: 'BNB Smart Chain',
      tokens: ['BNB', 'BUSD', 'USDT'],
      icon: FaExchangeAlt,
      addressFormat: '0x...'
    },
    TRX: {
      name: 'TRON',
      tokens: ['TRX', 'USDT', 'USDC'],
      icon: FaDollarSign,
      addressFormat: 'T...'
    },
    ADA: {
      name: 'Cardano',
      tokens: ['ADA'],
      icon: SiCardano,
      addressFormat: 'addr...'
    },
    DOT: {
      name: 'Polkadot',
      tokens: ['DOT'],
      icon: SiPolkadot,
      addressFormat: '1...'
    }
  };

  const exchangeRate = {
    ETH: 3000,
    BNB: 300,
    TRX: 0.08,
    ADA: 0.5,
    DOT: 5,
    USDT: 1,
    USDC: 1,
    BUSD: 1
  };

  const handleBlockchainChange = (e) => {
    const newBlockchain = e.target.value;
    setFormData({
      ...formData,
      blockchain: newBlockchain,
      cryptoType: blockchains[newBlockchain].tokens[0],
      recipientAddress: ''
    });
  };

  const toast = useToast();

  const handleSubmit = (e) => {
    e.preventDefault();
    toast({
      title: 'Pago Iniciado',
      description: `Procesando pago de ${formData.amount} ${formData.cryptoType}`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  const calculateUSDValue = () => {
    if (!formData.amount) return '0.00';
    return (formData.amount * exchangeRate[formData.cryptoType]).toFixed(2);
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <VStack spacing={6}>
          <FormControl isRequired>
            <FormLabel>Red Blockchain</FormLabel>
            <Select
              value={formData.blockchain}
              onChange={handleBlockchainChange}
            >
              {Object.entries(blockchains).map(([key, value]) => (
                <option key={key} value={key}>
                  {value.name}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Token</FormLabel>
            <Select
              value={formData.cryptoType}
              onChange={(e) => setFormData({...formData, cryptoType: e.target.value})}
            >
              {blockchains[formData.blockchain].tokens.map((token) => (
                <option key={token} value={token}>
                  {token}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Monto</FormLabel>
            <InputGroup>
              <InputLeftElement children={<FaBitcoin />} />
              <Input
                type="number"
                value={formData.amount}
                onChange={(e) => setFormData({...formData, amount: e.target.value})}
                placeholder="0.00"
              />
              <InputRightAddon children={formData.cryptoType} />
            </InputGroup>
            <Text fontSize="sm" color="gray.500" mt={1}>
              ≈ USD ${calculateUSDValue()}
            </Text>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Dirección del Destinatario</FormLabel>
            <InputGroup>
              <InputLeftElement children={<FaWallet />} />
              <Input
                value={formData.recipientAddress}
                onChange={(e) => setFormData({...formData, recipientAddress: e.target.value})}
                placeholder={`Ej: ${blockchains[formData.blockchain].addressFormat}`}
              />
            </InputGroup>
          </FormControl>

          <FormControl>
            <FormLabel>Descripción</FormLabel>
            <Input
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value})}
              placeholder="Descripción opcional del pago"
            />
          </FormControl>

          <Divider />

          <HStack justify="space-between" w="100%">
            <Text fontSize="sm">Comisión de red:</Text>
            <Badge colorScheme="green">Baja</Badge>
          </HStack>

          <StyledButton
            type="submit"
            colorScheme="blue"
            width="full"
            mt={4}
            _hover={{
              bg: 'blue.500',
            }}
          >
            Realizar Pago
          </StyledButton>
        </VStack>
      </form>
    </Box>
  );
};

export default PaymentForm; 