import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  SimpleGrid,
  Icon,
  useColorModeValue,
  // Button
} from '@chakra-ui/react';
import { FaQrcode, FaStore, FaMobile } from 'react-icons/fa';
import StyledButton from '../../components/common/StyledButton';

const PhysicalPayments = () => {
  return (
    <Box>
      <Box 
        bg={useColorModeValue('gray.50', 'gray.900')} 
        py={8}
        position="relative"
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: "url('https://img.freepik.com/free-photo/close-up-person-s-hand-holding-mobile-phone-showing-qr-code-screen_23-2147930162.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.50,
          zIndex: 0,
          filter: 'blur(0.5px)'
        }}
      >
        <Container maxW="container.xl" position="relative" zIndex={1}>
          <VStack spacing={6} align="stretch">
            <Box textAlign="center" py={4}>
              <Heading mb={3}>Pagos Físicos</Heading>
              <Text color={useColorModeValue('gray.800', 'gray.100')} fontWeight="medium">
                Acepta pagos en criptomonedas en tu negocio físico
              </Text>
            </Box>

            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
              <PaymentOption
                icon={FaQrcode}
                title="Código QR"
                description="Genera códigos QR dinámicos para recibir pagos instantáneos"
                buttonText="Generar QR"
              />
              <PaymentOption
                icon={FaStore}
                title="Terminal POS"
                description="Convierte tu dispositivo en un punto de venta cripto"
                buttonText="Configurar POS"
              />
              <PaymentOption
                icon={FaMobile}
                title="App Móvil"
                description="Acepta pagos desde nuestra aplicación móvil"
                buttonText="Descargar App"
              />
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

const PaymentOption = ({ icon, title, description, buttonText }) => {
  return (
    <Box
      p={6}
      shadow="xl"
      borderRadius="lg"
      bg={useColorModeValue('white', 'gray.700')}
      transition="all 0.3s"
      _hover={{ transform: 'translateY(-5px)' }}
    >
      <VStack spacing={4} align="center">
        <Icon as={icon} w={10} h={10} color="blue.500" />
        <Heading size="md">{title}</Heading>
        <Text textAlign="center" color={useColorModeValue('gray.600', 'gray.300')}>
          {description}
        </Text>
        <StyledButton
          colorScheme="blue"
          size="lg"
          width="full"
          _hover={{
            bg: 'blue.500',
          }}
        >
          {buttonText}
        </StyledButton>
      </VStack>
    </Box>
  );
};

export default PhysicalPayments; 