import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useColorModeValue,
  useToast,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { FaUser, FaBuilding, FaEnvelope, FaPhone } from 'react-icons/fa';
import StyledButton from '../../components/common/StyledButton';
import emailjs from '@emailjs/browser';

emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

if (!process.env.REACT_APP_EMAILJS_PUBLIC_KEY) {
  console.error('¡Advertencia! No se encontró la clave pública de EmailJS');
}
if (!process.env.REACT_APP_EMAILJS_SERVICE_ID) {
  console.error('¡Advertencia! No se encontró el ID de servicio de EmailJS');
}
if (!process.env.REACT_APP_EMAILJS_TEMPLATE_ID) {
  console.error('¡Advertencia! No se encontró el ID de plantilla de EmailJS');
}

const Contact = () => {
  const [formData, setFormData] = useState({
    nombreCompleto: '',
    empresa: '',
    email: '',
    telefono: '',
    detalles: ''
  });

  const toast = useToast();
  const navigate = useNavigate();

  const bgColor = useColorModeValue('blue.600', 'blue.700');
  const inputBg = useColorModeValue('whiteAlpha.900', 'whiteAlpha.200');
  const inputColor = useColorModeValue('gray.800', 'white');
  const labelColor = useColorModeValue('yellow.300', 'yellow.200');
  const iconColor = useColorModeValue('blue.600', 'yellow.300');

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const templateParams = {
        nombre_completo: formData.nombreCompleto,
        empresa: formData.empresa || 'No especificada',
        email: formData.email,
        telefono: formData.telefono,
        detalles: formData.detalles
      };

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      toast({
        title: 'Mensaje Enviado',
        description: 'Nos pondremos en contacto contigo pronto.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setFormData({
        nombreCompleto: '',
        empresa: '',
        email: '',
        telefono: '',
        detalles: ''
      });

      // Redirigir al home después de 1 segundo
      setTimeout(() => {
        navigate('/', { replace: true });
      }, 1000);

    } catch (error) {
      toast({
        title: 'Error al enviar',
        description: 'Hubo un problema al enviar el formulario. Por favor, intenta nuevamente.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error al enviar email:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Box
      bg="rgba(0, 0, 0, 0.7)"
      py={6}
      minH="calc(100vh - 64px - 280px)"
      display="flex"
      flexDirection="column"
      position="relative"
      marginBottom="-4px"
      _before={{
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: "url('https://img.freepik.com/free-vector/flat-design-illustration-customer-support_23-2148887720.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 0.15,
        zIndex: 0,
        filter: 'blur(1px)'
      }}
    >
      <Container 
        maxW="container.md"
        bg={bgColor}
        color="white"
        borderRadius="xl"
        p={8}
        position="relative"
        boxShadow="xl"
        zIndex={1}
      >
        <VStack spacing={8}>
          <Heading size="xl" color="yellow.300" textAlign="center" mb={4}>
            CONTÁCTENOS
          </Heading>

          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <VStack spacing={6}>
              <FormControl isRequired>
                <FormLabel color={labelColor} fontSize="lg">NOMBRE COMPLETO *</FormLabel>
                <InputGroup size="lg">
                  <InputLeftElement color={iconColor}>
                    <FaUser />
                  </InputLeftElement>
                  <Input
                    name="nombreCompleto"
                    value={formData.nombreCompleto}
                    onChange={handleChange}
                    bg={inputBg}
                    color={inputColor}
                    borderColor="whiteAlpha.400"
                    _hover={{ 
                      borderColor: 'yellow.300',
                      bg: 'whiteAlpha.400'
                    }}
                    _focus={{ 
                      borderColor: 'yellow.300',
                      bg: 'whiteAlpha.400',
                      boxShadow: '0 0 0 1px var(--chakra-colors-yellow-300)'
                    }}
                    height="14"
                    fontSize="md"
                  />
                </InputGroup>
              </FormControl>

              <FormControl>
                <FormLabel color={labelColor} fontSize="lg">EMPRESA (OPCIONAL)</FormLabel>
                <InputGroup size="lg">
                  <InputLeftElement color={iconColor}>
                    <FaBuilding />
                  </InputLeftElement>
                  <Input
                    name="empresa"
                    value={formData.empresa}
                    onChange={handleChange}
                    bg={inputBg}
                    color={inputColor}
                    borderColor="whiteAlpha.400"
                    _hover={{ 
                      borderColor: 'yellow.300',
                      bg: 'whiteAlpha.400'
                    }}
                    _focus={{ 
                      borderColor: 'yellow.300',
                      bg: 'whiteAlpha.400',
                      boxShadow: '0 0 0 1px var(--chakra-colors-yellow-300)'
                    }}
                    height="14"
                    fontSize="md"
                  />
                </InputGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel color={labelColor} fontSize="lg">EMAIL *</FormLabel>
                <InputGroup size="lg">
                  <InputLeftElement color={iconColor}>
                    <FaEnvelope />
                  </InputLeftElement>
                  <Input
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    bg={inputBg}
                    color={inputColor}
                    borderColor="whiteAlpha.400"
                    _hover={{ 
                      borderColor: 'yellow.300',
                      bg: 'whiteAlpha.400'
                    }}
                    _focus={{ 
                      borderColor: 'yellow.300',
                      bg: 'whiteAlpha.400',
                      boxShadow: '0 0 0 1px var(--chakra-colors-yellow-300)'
                    }}
                    height="14"
                    fontSize="md"
                  />
                </InputGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel color={labelColor} fontSize="lg">TELÉFONO *</FormLabel>
                <InputGroup size="lg">
                  <InputLeftElement color={iconColor}>
                    <FaPhone />
                  </InputLeftElement>
                  <Input
                    name="telefono"
                    value={formData.telefono}
                    onChange={handleChange}
                    bg={inputBg}
                    color={inputColor}
                    borderColor="whiteAlpha.400"
                    _hover={{ 
                      borderColor: 'yellow.300',
                      bg: 'whiteAlpha.400'
                    }}
                    _focus={{ 
                      borderColor: 'yellow.300',
                      bg: 'whiteAlpha.400',
                      boxShadow: '0 0 0 1px var(--chakra-colors-yellow-300)'
                    }}
                    height="14"
                    fontSize="md"
                  />
                </InputGroup>
              </FormControl>

              <FormControl isRequired>
                <FormLabel color={labelColor} fontSize="lg">DETALLES DE SU CONSULTA *</FormLabel>
                <Textarea
                  name="detalles"
                  value={formData.detalles}
                  onChange={handleChange}
                  bg={inputBg}
                  color={inputColor}
                  borderColor="whiteAlpha.400"
                  _hover={{ 
                    borderColor: 'yellow.300',
                    bg: 'whiteAlpha.400'
                  }}
                  _focus={{ 
                    borderColor: 'yellow.300',
                    bg: 'whiteAlpha.400',
                    boxShadow: '0 0 0 1px var(--chakra-colors-yellow-300)'
                  }}
                  height="32"
                  fontSize="md"
                  resize="vertical"
                />
              </FormControl>

              <StyledButton
                type="submit"
                bg="yellow.300"
                color="blue.600"
                width="full"
                size="lg"
                height="14"
                fontSize="lg"
                fontWeight="bold"
                _hover={{ 
                  bg: 'yellow.400',
                  transform: 'translateY(-2px)'
                }}
                _active={{
                  bg: 'yellow.500'
                }}
              >
                ENVIAR
              </StyledButton>
            </VStack>
          </form>
        </VStack>
      </Container>
    </Box>
  );
};

export default Contact; 