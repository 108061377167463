import { Button } from '@chakra-ui/react';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
  position: relative;
  overflow: hidden;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: currentColor;
    transition: width 0.3s ease-in-out;
  }
  
  &:hover::after {
    width: 100%;
  }
  
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: translateY(-2px);
  }
`;

export default StyledButton; 