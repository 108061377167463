import React from 'react';
import {
  Box,
  Container,
  Heading,
  Text,
  SimpleGrid,
  Icon,
  VStack,
  Button,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaBitcoin, FaEthereum, FaMoneyBillWave, FaShieldAlt, FaChartLine, FaUserLock } from 'react-icons/fa';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  const handleLearnMore = () => {
    navigate('/about');
  };

  const handleGetStarted = () => {
    navigate('/wallet');
  };

  return (
    <Box>
      <Box 
        bg="rgba(0, 0, 0, 0.7)" 
        py={6}
        position="relative"
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: "url('https://img.freepik.com/premium-vector/bitcoin-cryptocurrency-payment-terminal-pos-terminal-confirms-payment-crypto-wallet-nfc-payment-concept_199064-457.jpg')",
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          opacity: 0.15,
          zIndex: 0,
          filter: 'blur(1px)'
        }}
      >
        <Container 
          maxW="container.xl"
          position="relative"
          zIndex={1}
        >
          <Stack 
            direction={{ base: 'column', md: 'row' }} 
            spacing={8} 
            align="center"
            bg={useColorModeValue('whiteAlpha.800', 'blackAlpha.700')}
            p={8}
            borderRadius="xl"
            boxShadow="xl"
          >
            <VStack spacing={6} align="flex-start" flex={1}>
              <Heading size="2xl" color="blue.600">
                Bienvenido a Datafono Crypto
              </Heading>
              <Text 
                fontSize="xl" 
                color={useColorModeValue('gray.800', 'gray.100')}
                fontWeight="medium"
              >
                Tu puerta de entrada segura para pagos y transacciones con criptomonedas. Rápido, confiable y respaldado por miles.
              </Text>
              <Stack direction={{ base: 'column', sm: 'row' }} spacing={4}>
                <Button 
                  size="lg" 
                  colorScheme="blue"
                  onClick={handleGetStarted}
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                  }}
                >
                  Comenzar
                </Button>
                <Button 
                  size="lg" 
                  variant="outline" 
                  colorScheme="blue"
                  onClick={handleLearnMore}
                  _hover={{
                    transform: 'translateY(-2px)',
                    boxShadow: 'lg',
                  }}
                >
                  Saber Más
                </Button>
              </Stack>
            </VStack>
            <Box flex={1}>
              <ImageCarousel />
            </Box>
          </Stack>
        </Container>
      </Box>

      {/* Sección de Características */}
      <Box
        bg="rgba(0, 0, 0, 0.5)"
        position="relative"
        py={20}
        _before={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0.15,
          zIndex: 0,
          filter: 'blur(1px)'
        }}
      >
        <Container maxW="container.xl" position="relative" zIndex={1}>
          <VStack spacing={12}>
            <Heading textAlign="center" mb={4} color="yellow.300">Nuestras Características</Heading>
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
              <Feature
                icon={FaShieldAlt}
                title="Transacciones Seguras"
                text="Seguridad de nivel empresarial para todas tus transacciones con criptomonedas"
              />
              <Feature
                icon={FaChartLine}
                title="Análisis en Tiempo Real"
                text="Rastrea tus pagos y monitorea las tendencias del mercado en tiempo real"
              />
              <Feature
                icon={FaUserLock}
                title="Privado y Seguro"
                text="Tus datos están encriptados y nunca se comparten con terceros"
              />
              <Feature
                icon={FaBitcoin}
                title="Múltiples Criptomonedas"
                text="Soporte para Bitcoin, Ethereum y otras criptomonedas principales"
              />
              <Feature
                icon={FaMoneyBillWave}
                title="Pagos Instantáneos"
                text="Procesa pagos de forma rápida y segura"
              />
              <Feature
                icon={FaEthereum}
                title="Comisiones Bajas"
                text="Tarifas de transacción y tipos de cambio competitivos"
              />
            </SimpleGrid>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

const Feature = ({ icon, title, text }) => {
  return (
    <Box p={6} shadow="lg" borderRadius="lg" bg={useColorModeValue('white', 'gray.800')} 
         transition="all 0.3s" _hover={{ transform: 'translateY(-5px)' }}>
      <Icon as={icon} w={10} h={10} color="blue.500" mb={4} />
      <Heading size="md" mb={2}>{title}</Heading>
      <Text color={useColorModeValue('gray.600', 'gray.300')}>{text}</Text>
    </Box>
  );
};

export default Home; 