import React from 'react';
import {
  Box,
  Container,
  Stack,
  SimpleGrid,
  Text,
  Link,
  VStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  Icon,
  useColorModeValue
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaTwitter, FaYoutube, FaInstagram, FaArrowRight } from 'react-icons/fa';

const Footer = () => {
  const bgColor = useColorModeValue('blue.600', 'blue.700');

  return (
    <Box 
      as="footer"
      bg={bgColor} 
      color="white"
      width="100%"
      position="relative"
      boxShadow="0 -4px 6px -1px rgba(0, 0, 0, 0.1)"
    >
      <Container 
        as={Stack} 
        maxW="container.xl" 
        py={10}
      >
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8}>
          {/* Columna 1 - Logo y Descripción */}
          <VStack align="flex-start" spacing={4}>
            <Heading size="md">Datafono Crypto</Heading>
            <Text>
              Facilitando pagos con criptomonedas de manera segura y eficiente.
            </Text>
            <Stack direction="row" spacing={6}>
              <Link href="#" isExternal>
                <Icon as={FaTwitter} w={6} h={6} />
              </Link>
              <Link href="#" isExternal>
                <Icon as={FaYoutube} w={6} h={6} />
              </Link>
              <Link href="#" isExternal>
                <Icon as={FaInstagram} w={6} h={6} />
              </Link>
            </Stack>
          </VStack>

          {/* Columna 2 - Enlaces */}
          <VStack align="flex-start" spacing={4}>
            <Heading size="sm" color="yellow.300">Enlaces Rápidos</Heading>
            <Button as={RouterLink} to="/" variant="link" color="whiteAlpha.900" _hover={{ color: 'yellow.300' }}>
              Inicio
            </Button>
            <Button as={RouterLink} to="/about" variant="link" color="whiteAlpha.900" _hover={{ color: 'yellow.300' }}>
              Nosotros
            </Button>
            <Button as={RouterLink} to="/contact" variant="link" color="whiteAlpha.900" _hover={{ color: 'yellow.300' }}>
              Contacto
            </Button>
            <Button as={RouterLink} to="/virtual-payments" variant="link" color="whiteAlpha.900" _hover={{ color: 'yellow.300' }}>
              Pagos Virtuales
            </Button>
          </VStack>

          {/* Columna 3 - Newsletter */}
          <VStack align="flex-start" spacing={4}>
            <Heading size="sm" color="yellow.300">Newsletter</Heading>
            <Text color="whiteAlpha.900">
              Mantente actualizado con las últimas noticias y actualizaciones
            </Text>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                placeholder="Tu email"
                bg="whiteAlpha.300"
                color="white"
                borderColor="whiteAlpha.400"
                _placeholder={{ color: 'whiteAlpha.700' }}
                _hover={{ 
                  borderColor: 'yellow.300',
                  bg: 'whiteAlpha.400'
                }}
                _focus={{ 
                  borderColor: 'yellow.300',
                  bg: 'whiteAlpha.400',
                  boxShadow: '0 0 0 1px var(--chakra-colors-yellow-300)'
                }}
              />
              <InputRightElement width="4.5rem">
                <Button 
                  h="1.75rem" 
                  size="sm" 
                  bg="yellow.300"
                  color="blue.600"
                  _hover={{ 
                    bg: 'yellow.400',
                    transform: 'translateY(-1px)'
                  }}
                  _active={{
                    bg: 'yellow.500'
                  }}
                >
                  <Icon as={FaArrowRight} />
                </Button>
              </InputRightElement>
            </InputGroup>
          </VStack>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Footer; 