import React from 'react';
import {
  Box,
  Container,
  Heading,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Text,
  VStack,
  HStack,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaBitcoin, FaEthereum } from 'react-icons/fa';
import { SiTether } from 'react-icons/si';

const Transactions = () => {
  const transactions = [
    {
      id: 1,
      date: '2024-02-20',
      type: 'BTC',
      amount: '0.025',
      status: 'completed',
      address: '0x742d...f44e',
    },
    {
      id: 2,
      date: '2024-02-19',
      type: 'ETH',
      amount: '1.5',
      status: 'pending',
      address: '0x123...abc',
    },
    // Añade más transacciones aquí
  ];

  const getCryptoIcon = (type) => {
    switch (type) {
      case 'BTC':
        return FaBitcoin;
      case 'ETH':
        return FaEthereum;
      case 'USDT':
        return SiTether;
      default:
        return FaBitcoin;
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 'completed':
        return 'green';
      case 'pending':
        return 'yellow';
      case 'failed':
        return 'red';
      default:
        return 'gray';
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={6} align="stretch">
        <Box textAlign="center">
          <Heading mb={3}>Historial de Transacciones</Heading>
          <Text color={useColorModeValue('gray.600', 'gray.300')}>
            Visualiza todas tus transacciones realizadas
          </Text>
        </Box>

        <Box overflowX="auto">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Fecha</Th>
                <Th>Tipo</Th>
                <Th>Cantidad</Th>
                <Th>Estado</Th>
                <Th>Dirección</Th>
              </Tr>
            </Thead>
            <Tbody>
              {transactions.map((tx) => (
                <Tr key={tx.id}>
                  <Td>{tx.date}</Td>
                  <Td>
                    <HStack>
                      <Icon as={getCryptoIcon(tx.type)} />
                      <Text>{tx.type}</Text>
                    </HStack>
                  </Td>
                  <Td>{tx.amount}</Td>
                  <Td>
                    <Badge colorScheme={getStatusColor(tx.status)}>
                      {tx.status}
                    </Badge>
                  </Td>
                  <Td>
                    <Text fontSize="sm" fontFamily="mono">
                      {tx.address}
                    </Text>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </VStack>
    </Container>
  );
};

export default Transactions; 