import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Web3ReactProvider } from '@web3-react/core';
import { ChakraProvider } from '@chakra-ui/react';
import { getLibrary } from './utils/wallet';
import Home from './pages/Home/Home';
import Payment from './pages/Payment/Payment';
import PhysicalPayments from './pages/PhysicalPayments/PhysicalPayments';
import AboutUs from './pages/AboutUs/AboutUs';
import Contact from './pages/Contact/Contact';
import Transactions from './pages/Transactions/Transactions';
import Layout from './components/Layout/Layout';

function App() {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Router>
        <ChakraProvider>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/physical-payments" element={<PhysicalPayments />} />
              <Route path="/virtual-payments" element={<Payment />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </Layout>
        </ChakraProvider>
      </Router>
    </Web3ReactProvider>
  );
}

export default App; 