import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';

const Layout = ({ children }) => {
  return (
    <Flex 
      direction="column" 
      minH="100vh"
      position="relative"
    >
      <Navigation />
      <Box 
        flex="1"
        display="flex"
        flexDirection="column"
      >
        {children}
      </Box>
      <Footer />
    </Flex>
  );
};

export default Layout; 