import React from 'react';
import Slider from 'react-slick';
import { Box, Image } from '@chakra-ui/react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageCarousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false
        }
      }
    ]
  };

  const images = [
    {
      url: "https://images.unsplash.com/photo-1518546305927-5a555bb7020d",
      alt: "Bitcoin Cryptocurrency"
    },
    {
      url: "https://images.unsplash.com/photo-1621416894569-0f39ed31d247",
      alt: "Ethereum Network"
    },
    {
      url: "https://images.unsplash.com/photo-1622630998477-20aa696ecb05",
      alt: "Crypto Trading"
    },
    {
      url: "https://images.unsplash.com/photo-1629339942248-45d4b10c8c2f",
      alt: "Digital Currency"
    }
  ];

  return (
    <Box 
      maxW="500px" 
      mx="auto" 
      borderRadius="lg" 
      overflow="hidden"
      boxShadow="xl"
      bg="gray.800"
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <Box key={index} p={2}>
            <Image
              src={image.url}
              alt={image.alt}
              borderRadius="lg"
              w="full"
              h={{ base: "300px", md: "400px" }}
              objectFit="cover"
              loading="lazy"
              transition="transform 0.3s ease-in-out"
              _hover={{ transform: 'scale(1.05)' }}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ImageCarousel; 