import React from 'react';
import { Box, Container, Heading, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import PaymentForm from '../../components/PaymentForm/PaymentForm';

const Payment = () => {
  return (
    <Box>
      <Box 
        bg={useColorModeValue('gray.50', 'gray.900')} 
        py={8}
        position="relative"
      >
        <Container maxW="container.xl" position="relative" zIndex={1}>
          <VStack spacing={6} align="stretch">
            <Box textAlign="center" py={4}>
              <Heading mb={3}>Pagos Virtuales</Heading>
              <Text color={useColorModeValue('gray.600', 'gray.300')}>
                🔒 Tus transacciones están protegidas por la tecnología blockchain
              </Text>
            </Box>
            
            <Box 
              bg={useColorModeValue('white', 'gray.700')}
              p={6} 
              borderRadius="lg" 
              boxShadow="xl"
            >
              <PaymentForm />
            </Box>
          </VStack>
        </Container>
      </Box>
    </Box>
  );
};

export default Payment; 